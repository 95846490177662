<template>
    <div class="index_bottom">
        <div v-show="index_centent" class="index_centent">
            <div class="bottom_centent_box">
                <div class="left_one" style="float: left;">
                    <p>关于我们</p>
                    <p>ABOUT US</p>
                    <a style="margin-top: 30px;" href="/">企业介绍</a>
                    <a href="/">企业资质</a>
                    <a href="/">企业荣誉</a>
                    <a href="/">企业团队</a>
                    <a href="/">企业文化</a>
                    <a href="/">在研课题</a>
                    <a href="/">加入我们</a>
                </div>
                <div class="left_one" style="float: left;margin-left: 50px;">
                    <p>业务矩阵</p>
                    <p>ABOUT US</p>
                    <a style="margin-top: 30px;" href="/">数字+展陈</a>
                    <a href="/">数字+文保</a>
                    <a href="/">数字+文旅</a>
                    <a href="/">数字+城市更新</a>
                    <a href="/">文博元宇宙</a>
                    <a href="/">文博技术服务</a>
                </div>
                <div class="left_one" style="float: left;margin-left:50px;">
                    <p>亮点项目</p>
                    <p>ABOUT US</p>
                    <a style="margin-top: 30px;" href="/">2019北京世界园艺博览会中国国家馆</a>
                    <a href="/">孔子博物馆</a>
                    <a href="/">中国大运河非物质文化遗产展示中心</a>
                    <a href="/">荆门市博物馆</a>
                    <a href="/">合肥公共安全研究院二期安全文化教育与科普基地</a>
                    <a href="/">天津市公共安全教育体验馆</a>
                </div>
                <div class="left_one" style="float: right;margin-right:-10px;">
                    <p>联系我们</p>
                    <p>CONTACT US</p>
                    <!--电话  -->
                    <div class="phone">
                        <img src="../imager/bottom/phone.png" alt="">
                        <span>86(010)-62648099</span>
                    </div>
                    <!-- 地址 -->
                    <div class="address">
                        <img src="../imager/bottom/home.png" alt=""><span>北京市海淀区双清路甲1号凯时广场A601</span>
                    </div>
                </div>
                <!-- 微信 -->
                <div class="wx">
                    <img src="../imager/bottom/wx.png" alt="">
                </div>
                <!-- 微博 -->
                <div class="wb">
                    <img src="../imager/bottom/wb.png" alt="">
                </div>
            </div>
        </div>
        <!-- 红色 -->
        <div class="bottom_red">
             <p>国文创（北京）文化科技有限公司</p>
             <p class="p_bottom">NATIONAL CULTURAL CREATION TECHNOLOGY CO.,LTD</p>
             <p>京ICP备19056201号-6</p>
             <div class="office">
                <img src="../imager/bottom/jc.png" style="margin-right: 10px;width: 25px;" alt="">
                <p>京公网安备 11010802040837号</p>
             </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bottom',
     props:{
        indexcententOpne:Boolean
     },
    data() {
        return {
            index_centent:true
        };
    },

    mounted() {
          this.index_centent=this.indexcententOpne;
    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.index_bottom {
    width: 100%;
    // height: 700px;
    background: white;


    .index_centent {
        width: 1200px;
        height: 500px;
        margin-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        .bottom_centent_box {
            width: 100%;
            height: 400px;
            color: #434343;
            position: relative;

            .left_one {
                p:nth-child(1) {
                    font-size: 30px;
                    letter-spacing: 3px;
                    font-family: 'Sofia Sans2';

                }

                p:nth-child(2) {
                    font-size: 18px;
                    letter-spacing: 3px;
                    font-weight: 600;
                    font-family: 'Sofia Sans2';

                }

                a {
                    clear: both;
                    float: left;
                    text-decoration: none;
                    color: #6d6d6d;
                    font-size: 14px;
                    margin-top: 5px;
                    letter-spacing: 2px;
                    margin-left: 5px;
                }

                .phone {
                    margin-top: 50px;
                    font-family: 'JTC';
                    line-height: 50px;

                    img {
                        width: 40px;
                        float: left;
                    }

                    span {
                        float: left;
                        margin-left: 10px;
                        margin-top: -2px;
                        display: inline-block;
                    }

                }

                // 地址
                .address {
                    clear: both;
                    margin-top: 30px;
                    font-family: 'JTC';
                    line-height: 50px;

                    img {
                        width: 40px;
                        float: left;
                    }

                    span {
                        float: left;
                        margin-left: 10px;
                        margin-top: 2px;
                        display: inline-block;
                    }
                }
            }

            // 微信
            .wx {
                position: absolute;
                cursor: pointer;
                right: 120px;
                bottom: 30px;

                img {
                    width: 50px;
                }
            }

            // 微博
            .wb {
                position: absolute;
                cursor: pointer;
                right: 30px;
                bottom: 30px;

                img {
                    width: 50px;
                }
            }
        }
    }

    // 红色
    .bottom_red {
        width: 100%;
        height: 250px;
        background: #b7001f;
        p:nth-child(1){
            color: white;
            text-align: center;
            font-size: 19px;
            padding-top: 80px;
        }
        .p_bottom{
            color: white;
            text-align: center;
            font-size: 4px;
            scale: 0.88;
        // font-family: 'Sofia Sans';
        letter-spacing: 0px;
        margin-top: 3px;
        }
        p:nth-child(3){
            color: white;
            text-align: center;
            font-size: 16px;
            padding-top:30px;
            font-family: 'JT';

        }
        .office{
            width: 100%;
            height: 100px;
            margin-top: -30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            p{
            font-size: 15px;
            letter-spacing: 0px;
            font-family: 'JT';

            }
        }
    }
}</style>