<template>
  <div class="home" v-cloak>
    <!-- 头部 -->
    <Hreader :active="0" />
    <!-- 图片 -->
    <div class="index_fotter">
      <img src="../imager/test/fotter_img.jpg" alt="">
      <!-- 最新咨询 -->
      <div class="Consultation"> </div>
      <div class="Consultationtext">
        <div class="Consultationtext_centent">
          <h1>最新资讯</h1>
          <h1 style="            font-family: 'JTC';
">Latest information</h1>
          <p>&nbsp;&nbsp;2019北京世界园艺博览会中国馆以生生不息为理念，锦绣中华为意象，园艺植物为载体，生态人文为核心, 展陈动线设计清晰，内容主题渐次升华, 用水墨、纱幔、光影，铺陈出一幅幅“可望、可
            行、可游、可居”的数字山水长卷。通过动静结合的数字技术，实景相呼应，当代科技与传统技艺相融汇，呈现出人与自然和谐共生的中国生态文化精髓。</p>
          <!-- Latest information
        2019北京世界园艺博览会中国馆以生生不息为理念，锦绣中华为意象，园艺植物为载体，生态人文为核心, 展陈动线设计清晰，内容主题渐次升华, 用水墨、纱幔、光影，铺陈出一幅幅“可望、可
        行、可游、可居”的数字山水长卷。通过动静结合的数字技术，实景相呼应，当代科技与传统技艺相融汇，呈现出人与自然和谐共生的中国生态文化精髓。 -->
        </div>
      </div>
    </div>

    <!-- 轮播图 -->
    <div class="news_banner">
      <div class="news_banner_centen">
        <div class="title">
          <div class="title_left">新闻中心</div>
          <div class="title_left_none">|</div>
          <div class="title_right">NEWS</div>
          <div class="title_bottom">
            关注企业动态,了解最新咨询
          </div>
        </div>
        <div class="title_right_bottom">
          关注企业动态,了解最新咨询,我们希冀以创新、数字、艺术、服务为核心竞争力,打造行业标杆企业。
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <div style="margin-top: 60px;">
      <el-carousel :interval="4000" type="card" height="600px" @change="tabbanner">
        <el-carousel-item v-for="item in bannerlist">
          <img :src="item.url" alt="" class="banner_img">
        </el-carousel-item>
      </el-carousel>
      <p class="banner_introduce">{{ introduce }}</p>

    </div>
    <!-- 业务矩阵 -->
    <div class="business">
      <div class="business_centen">
        <div class="title">
          <div class="title_left">业务矩阵</div>
          <div class="title_left_none">|</div>
          <div class="title_right">BUSINESS SEGMENT</div>
        </div>
        <div class="title_right_bottom">
          关注心思潮、新技术对文化和艺术的可持续性传承与传播、我们致力于达成数字科技与文博、文保、文旅之间的融合与创新。
        </div>
        <!-- 列表 -->
        <div class="businesslist">
          <!-- 数字 -->
          <div class="businesslist_box">
            <!-- 滤镜 -->
            <div class="cententone">
            </div>
            <div class="cententone_big">
              <div class="cententone_title">
                数字展陈
              </div>
              <P class="cententone_title_p">+</P>
              <div class="cententone_title_eg">
                <P>digital </P>
                <span>+</span>
                <P class="exh">Exhibition</P>
              </div>
              <a href="">了解更多
              </a> <img src="../imager/list/jiantou.png" class="jiantou" alt="">
            </div>
          </div>
          <!-- 数字 文保 -->
          <div class="businesslist_box">
            <!-- 滤镜 -->
            <div class="cententone">
            </div>
            <div class="cententone_big">
              <div class="cententone_title">
                数字文保
              </div>
              <P class="cententone_title_p">+</P>
              <div class="cententone_title_eg">
                <P>digital </P>
                <span>+</span>
                <P class="exh">Cultural relics protection</P>
              </div>
              <a href="">了解更多
              </a>
              <img src="../imager/list/jiantou.png" class="jiantou" alt="">
            </div>
          </div>
          <!-- 数字文旅 -->
          <div class="businesslist_box">
            <!-- 滤镜 -->
            <div class="cententone">
            </div>
            <div class="cententone_big">
              <div class="cententone_title">
                数字文保
              </div>
              <P class="cententone_title_p">+</P>
              <div class="cententone_title_eg">
                <P>digital </P>
                <span>+</span>
                <P class="exh">Cultural tourism</P>
              </div>
              <a href="">了解更多
              </a> <img src="../imager/list/jiantou.png" class="jiantou" alt="">
            </div>
          </div>
          <!-- 数字 -->
          <div class="businesslist_box">
            <!-- 滤镜 -->
            <div class="cententone">
            </div>
            <div class="cententone_big">
              <div class="cententone_title" style="margin-left: -20px;">
                数字
              </div>
              <P class="cententone_title_p" style="margin-left: 50px;">+</P>
              <div class="cententone_two">
                城市更新
              </div>
              <div class="cententone_title_eg">
                <P>digital </P>
                <span>+</span>
                <P class="exh"> Exhibition</P>
              </div>
              <a href="">了解更多

              </a> <img src="../imager/list/jiantou.png" class="jiantou" alt="">
            </div>
          </div>
          <!-- 文博 -->
          <div class="businesslist_box">
            <!-- 滤镜 -->
            <div class="cententone">
            </div>
            <div class="cententone_big">
              <div class="cententone_title" style="margin-left: -20px;">
                文博
              </div>
              <div class="cententone_two">
                元宇宙
              </div>
              <div class="cententone_title_eg">
                <P>digital </P>
                <span>+</span>
                <P class="exh">Metaverse</P>
              </div>
              <a href="">了解更多
              </a> <img src="../imager/list/jiantou.png" class="jiantou" alt="">
            </div>
          </div>
          <!-- 文博技术支持 -->
          <div class="businesslist_box">
            <!-- 滤镜 -->
            <div class="cententone">
            </div>
            <div class="cententone_big">
              <div class="cententone_title" style="margin-left: -20px;">
                文博
              </div>
              <div class="cententone_two">
                技术支持
              </div>
              <div class="cententone_title_eg">
                <P>digital </P>
                <span>+</span>
                <P class="exh">Exhibition</P>
              </div>
              <a href="">了解更多
              </a><img src="../imager/list/jiantou.png" class="jiantou" alt="">
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 经典案例 -->

    <div class="business" style="height: 1200px;">
      <div class="business_centen">
        <div class="title">
          <div class="title_left">经典案例</div>
          <div class="title_left_none">|</div>
          <div class="title_right">CLASSIC CASES</div>
        </div>
        <div class="title_right_bottom" style="width: 750px;">
          &nbsp;&nbsp;&nbsp; 自成立以来，我们在文化产业领域积累了众多案例，建立了良好声誉，已成为文博、文保、文旅等领域数
          字化体验升级的先行者及优质合作伙伴。
        </div>
      </div>
      <div class="jdanlibox">
        <!-- 列表三个滚动条 -->
        <el-carousel height="300px" direction="vertical" :autoplay="true" indicator-position="none"
          @change="tabbannerone(1)" :interval="5000">
          <el-carousel-item v-for="item in jdanli" :key="item.img">
            <img :src="item.img" alt="" @mouseenter="handleMouseEnter(1)" @mouseleave="handleMouseLeave(1)">
            <div :class="title" @animationend="animationEnd(1)">
              <p style="font-family: 'Sofia Sans2';">{{ item.chinese }}</p>
              <p style="font-family: 'Sofia Sans2';">{{ item.chinesetwo }}</p>
              <p style="font-weight: 600;">{{ item.enone }}</p>
              <p style="font-weight: 600;">{{ item.entwo }}</p>
            </div>
            <!-- 遮罩 -->
            <div :class="bannerPone_ZZ" @mouseenter="handleMouseEnter(1)" @mouseleave="handleMouseLeave(1)">
              <div class="bannerPone_ZZleft">
                <img src="../imager/list/zhelogo1.png" alt="">
                <!-- 点击显示左侧 -->
                <img src="../imager/list/jiantou.png" alt="" @click="rightOneBlock(1)">
              </div>
              <div :class="bannerPone_ZZright">
                <P>2019北京世界园艺博览会中国国家馆</P>
                <p class="twnp">China National Pavilion Of 2019 Beijing World Horticultural Expo 2019</p>
                <P>孔子博物馆</P>
                <p class="twnp">Confucius Museum</p>
                <P>中国大运河非物质文化遗产展示中心</P>
                <p class="twnp">China Grand Canal Intangible Cultural Heritage Exhibition Center</p>
                <P>荆门市博物馆新馆</P>
                <p class="twnp">Jingmen Museum Re-new Museum Exhibition Project</p>
              </div>
            </div>
            <!-- 遮罩结束 -->
          </el-carousel-item>
        </el-carousel>

        <el-carousel style="margin-top: 50px;" height="300px" direction="vertical" :autoplay="true"
          indicator-position="none" @change="tabbannerone(2)" :interval="5000">
          <el-carousel-item v-for="item in jdanli2" :key="item.img">
            <img :src="item.img" alt="" @mouseenter="handleMouseEnter(2)" @mouseleave="handleMouseLeave(2)">
            <div :class="title2" @animationend="animationEnd(2)">
              <p style="font-family: 'Sofia Sans2';">{{ item.chinese }}</p>
              <p style="font-family: 'Sofia Sans2';">{{ item.chinesetwo }}</p>
              <p style="font-weight: 600;">{{ item.enone }}</p>
              <p style="font-weight: 600;">{{ item.entwo }}</p>
            </div>
            <!-- 遮罩 -->
            <div :class="bannerPone_ZZ2" @mouseenter="handleMouseEnter(2)" @mouseleave="handleMouseLeave(2)">
              <div class="bannerPone_ZZleft">
                <img src="../imager/list/zhelogo2.png" alt="">
                <!-- 点击显示左侧 -->
                <img src="../imager/list/jiantou.png" alt="" @click="rightOneBlock(2)">
              </div>
              <div :class="bannerPone_ZZright2">
                <P>2019北京世界园艺博览会中国国家馆</P>
                <p class="twnp">China National Pavilion Of 2019 Beijing World Horticultural Expo 2019</p>
                <P>孔子博物馆</P>
                <p class="twnp">Confucius Museum</p>
                <P>中国大运河非物质文化遗产展示中心</P>
                <p class="twnp">China Grand Canal Intangible Cultural Heritage Exhibition Center</p>
                <P>荆门市博物馆新馆</P>
                <p class="twnp">Jingmen Museum Re-new Museum Exhibition Project</p>
              </div>
            </div>
            <!-- 遮罩结束 -->
          </el-carousel-item>
        </el-carousel>
        <el-carousel style="margin-top: 50px;" height="300px" direction="vertical" :autoplay="true"
          indicator-position="none" @change="tabbannerone(3)" :interval="5000">
          <el-carousel-item v-for="item in jdanli3" :key="item.img">
            <img :src="item.img" alt="" @mouseenter="handleMouseEnter(3)" @mouseleave="handleMouseLeave(3)">
            <div :class="title3" @animationend="animationEnd(3)">
              <p style="font-family: 'Sofia Sans2';">{{ item.chinese }}</p>
              <p style="font-family: 'Sofia Sans2';">{{ item.chinesetwo }}</p>
              <p style="font-weight: 600;">{{ item.enone }}</p>
              <p style="font-weight: 600;">{{ item.entwo }}</p>
            </div>
            <!-- 遮罩 -->
            <div :class="bannerPone_ZZ3" @mouseenter="handleMouseEnter(3)" @mouseleave="handleMouseLeave(3)">
              <div class="bannerPone_ZZleft">
                <img src="../imager/list/zhelogo3.png" alt="">
                <!-- 点击显示左侧 -->
                <img src="../imager/list/jiantou.png" alt="" @click="rightOneBlock(3)">
              </div>
              <div :class="bannerPone_ZZright3">
                <P>2019北京世界园艺博览会中国国家馆</P>
                <p class="twnp">China National Pavilion Of 2019 Beijing World Horticultural Expo 2019</p>
                <P>孔子博物馆</P>
                <p class="twnp">Confucius Museum</p>
                <P>中国大运河非物质文化遗产展示中心</P>
                <p class="twnp">China Grand Canal Intangible Cultural Heritage Exhibition Center</p>
                <P>荆门市博物馆新馆</P>
                <p class="twnp">Jingmen Museum Re-new Museum Exhibition Project</p>
              </div>
            </div>
            <!-- 遮罩结束 -->
          </el-carousel-item>
        </el-carousel>




      </div>
    </div>
    <!-- 结束 -->
    <bottom :index_cententOpne="true"></bottom>
  </div>
</template>

<script>
import Bottom from '../components/bottom.vue'
import Hreader from '../components/top.vue'

export default {
  name: 'HomeView',
  components: {
    Bottom, Hreader
  },
  data() {
    return {

      // 轮播图
      bannerlist: [
        {
          url: require('../imager/test/fotter_img.jpg'),
          introduce: "2019北京世界园艺博览会中国馆以生生不息为理念，锦绣中华为意象，园艺植物为载体，生态人文为核心, 展陈动线设计清晰，内容主题渐次升华, 用水墨、纱幔、光影，铺陈出一幅幅“可望、可行、可游、可居”的数字山水长卷。通过动静结合的数字技术，实景相呼应，当代科技与传统技艺相融汇，呈现出人与自然和谐共生的中国生态文化精髓。111111111111"
        },
        {
          url: require('../imager/test/fotter_img.jpg'),
          introduce: "2019北京世界园艺博览会中国馆以生生不息为理念，锦绣中华为意象，园艺植物为载体，生态人文为核心, 展陈动线设计清晰，内容主题渐次升华, 用水墨、纱幔、光影，铺陈出一幅幅“可望、可行、可游、可居”的数字山水长卷。通过动静结合的数字技术，实景相呼应，当代科技与传统技艺相融汇，呈现出人与自然和谐共生的中国生态文化精髓。222222222222222"
        }, {
          url: require('../imager/test/fotter_img.jpg'),
          introduce: "2019北京世界园艺博览会中国馆以生生不息为理念，锦绣中华为意象，园艺植物为载体，生态人文为核心, 展陈动线设计清晰，内容主题渐次升华, 用水墨、纱幔、光影，铺陈出一幅幅“可望、可行、可游、可居”的数字山水长卷。通过动静结合的数字技术，实景相呼应，当代科技与传统技艺相融汇，呈现出人与自然和谐共生的中国生态文化精髓。33333333333333333"
        }, {
          url: require('../imager/test/fotter_img.jpg'),
          introduce: "2019北京世界园艺博览会中国馆以生生不息为理念，锦绣中华为意象，园艺植物为载体，生态人文为核心, 展陈动线设计清晰，内容主题渐次升华, 用水墨、纱幔、光影，铺陈出一幅幅“可望、可行、可游、可居”的数字山水长卷。通过动静结合的数字技术，实景相呼应，当代科技与传统技艺相融汇，呈现出人与自然和谐共生的中国生态文化精髓。44444444444444"
        }
      ],
      introduce: "2019北京世界园艺博览会中国馆以生生不息为理念，锦绣中华为意象，园艺植物为载体，生态人文为核心, 展陈动线设计清晰，内容主题渐次升华, 用水墨、纱幔、光影，铺陈出一幅幅“可望、可行、可游、可居”的数字山水长卷。通过动静结合的数字技术，实景相呼应，当代科技与传统技艺相融汇，呈现出人与自然和谐共生的中国生态文化精髓。11111111111",
      title: "title  animate__animated animate__fadeInUp",
      title2: "title  animate__animated animate__fadeInUp",
      title3: "title  animate__animated animate__fadeInUp",
      bannerPone_ZZ: "bannerPone_ZZ",
      bannerPone_ZZright: "bannerPone_ZZright",
      bannerPone_ZZ2: "bannerPone_ZZ2",
      bannerPone_ZZright2: "bannerPone_ZZright",
      bannerPone_ZZ3: "bannerPone_ZZ3",
      bannerPone_ZZright3: "bannerPone_ZZright",
      // 经典案例
      jdanli: [
        {
          img: require('../imager/list/anli1.png'),
          chinese: "东方园林的数字美学映像1",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        },
        {
          img: require('../imager/list/ali2.png'),
          chinese: "东方园林的数字美学映像2",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        },
        {
          img: require('../imager/list/anli3.png'),
          chinese: "东方园林的数字美学映像3",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        }

      ],
      jdanli2: [
        {
          img: require('../imager/list/anli1.png'),
          chinese: "东方园林的数字美学映像1",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        },
        {
          img: require('../imager/list/ali2.png'),
          chinese: "东方园林的数字美学映像2",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        },
        {
          img: require('../imager/list/anli3.png'),
          chinese: "东方园林的数字美学映像3",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        }

      ],
      jdanli3: [
        {
          img: require('../imager/list/anli1.png'),
          chinese: "东方园林的数字美学映像1",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        },
        {
          img: require('../imager/list/ali2.png'),
          chinese: "东方园林的数字美学映像2",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        },
        {
          img: require('../imager/list/anli3.png'),
          chinese: "东方园林的数字美学映像3",
          chinesetwo: "师法自然 和而共生",
          enone: "CHINA NATIONAL PAVILION",
          entwo: "OF 2019 BEIJING WORLD HORTICULTURAL EXPO",
        }

      ]
    }

  },
  methods: {
    // 改变top
    btn(e) {
      this.topactove = e;
      console.log(e)
    },
    tabbanner(e) {
      this.introduce = this.bannerlist[e].introduce;
    },
    // 改变轮播1
    tabbannerone(e) {
      if (e == 1) {
        this.title = " title animate__animated animate__fadeInUp"
      }
      if (e == 2) {
        this.title2 = " title animate__animated animate__fadeInUp"
      }
      if (e == 3) {
        this.title3 = " title animate__animated animate__fadeInUp"
      }
    },
    animationEnd(e) {
      if (e == 1) {
        this.title = "title"
      }
      if (e == 2) {
        this.title2 = "title"
      }
      if (e == 3) {
        this.title3 = "title"
      }
    },

    rightOneBlock(e) {
      if (e == 1) {
        this.bannerPone_ZZright = "bannerPone_ZZright bannerPone_ZZrightactive animate__animated animate__fadeIn"
      }
      if (e == 2) {
        this.bannerPone_ZZright2 = "bannerPone_ZZright bannerPone_ZZrightactive animate__animated animate__fadeIn"
      }
      if (e == 3) {
        this.bannerPone_ZZright3 = "bannerPone_ZZright bannerPone_ZZrightactive animate__animated animate__fadeIn "
      }
    },

    // 滑入 轮播1
    handleMouseEnter(e) {
      if (e == 1) {
        this.bannerPone_ZZ = "bannerPone_ZZ Maskactive animate__animated animate__fadeInLeft"
      }
      if (e == 2) {
        this.bannerPone_ZZ2 = "bannerPone_ZZ Maskactive animate__animated animate__fadeInLeft"
      }
      if (e == 3) {
        this.bannerPone_ZZ3 = "bannerPone_ZZ Maskactive animate__animated animate__fadeInLeft"
      }
    },
    handleMouseLeave(e) {
      if (e == 1) {
        this.bannerPone_ZZ = "bannerPone_ZZ Maskactive    animate__animated animate__fadeOutLeft"
        this.bannerPone_ZZright = "bannerPone_ZZright  animate__animated animate__fadeOutLeft"
      }
      if (e == 2) {
        this.bannerPone_ZZ2 = "bannerPone_ZZ Maskactive    animate__animated animate__fadeOutLeft"
        this.bannerPone_ZZright2 = "bannerPone_ZZright  animate__animated animate__fadeOutLeft"
      }
      if (e == 3) {
        this.bannerPone_ZZ3 = "bannerPone_ZZ Maskactive    animate__animated animate__fadeOutLeft"
        this.bannerPone_ZZright3 = "bannerPone_ZZright  animate__animated animate__fadeOutLeft"
      }
    },



  }

}
</script>
<style lang="scss" scoped>
// 首页轮播
.banner_img {
  width: 100%;
  height: 100%;

}

.banner_introduce {
  width: 850PX;
  margin: 0 auto;
  color: #858484;
  font-size: 18px;
}

// 中间区域
.index_fotter {
  width: 100%;
  padding-top: 100px;
  height: calc(100vh - 100px);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }



  // 最新咨询
  .Consultation {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 250px;
    background: #a45045;
    opacity: 0.55;
  }

  .Consultationtext {
    position: absolute;
    width: 100%;
    height: 250px;
    bottom: 0;
    z-index: 999;
    color: white;
    display: flex;

    //  justify-content: center;
    .Consultationtext_centent {
      h1 {
        margin-left: 100px;
        margin-top: 40px;
        letter-spacing: 10px;
      }

      h1:nth-child(2) {
        margin-left: 100px;
        margin-top: 10px;
        letter-spacing: 0px;
      }

      p {
        margin-left: 100px;
        width: 1400px;
        line-height: 30px;
        overflow: hidden;
        margin-top: 10px;
        font-size: 15px;
      }
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

// 新闻中心
.news_banner {
  width: 100%;

  .news_banner_centen {
    margin: 0 auto;
    margin-top: 120px;
    width: 1380px;
    height: 100px;

    .title {
      width: 400px;
      height: 100%;
      line-height: 50px;
      float: left;
    }

    .title_left {
      font-size: 38px;
      font-weight: 550;
      letter-spacing: 5px;
      float: left;
    }

    .title_left_none {
      font-size: 32px;
      line-height: 48px;
      font-weight: 500;
      letter-spacing: 5px;
      float: left;
    }

    .title_right {
      font-size: 25px;
      font-weight: 550;
      line-height: 55px;
      float: left;
    }

    .title_bottom {
      clear: both;
      float: left;
      color: #d9d9d9;
      font-size: 18px;
      letter-spacing: 2px;
      font-weight: 550;
      letter-spacing: 3px;
    }

    .title_right_bottom {
      width: 600px;
      height: 100%;
      top: 0;
      float: right;
      color: #d9d9d9;
      font-size: 18px;
      // font-weight: 550;
      letter-spacing: 3px;
    }
  }
}

// 轮播图
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

// 业务矩阵
.business {
  width: 100%;
  height: 550px;

  .business_centen {
    margin: 0 auto;
    margin-top: 120px;
    width: 1380px;
    height: 100px;

    .title {
      width: 600px;
      height: 100%;
      line-height: 50px;
      float: left;
    }

    .title_left {
      font-size: 38px;
      font-weight: 550;
      letter-spacing: 5px;
      float: left;
    }

    .title_left_none {
      font-size: 32px;
      line-height: 48px;
      font-weight: 500;
      letter-spacing: 5px;
      float: left;
    }

    .title_right {
      font-size: 25px;
      font-weight: 550;
      line-height: 55px;
      float: left;
    }

    .title_right_bottom {
      width: 600px;
      height: 100%;
      top: 0;
      float: right;
      color: #d9d9d9;
      font-size: 18px;
      // font-weight: 550;
      letter-spacing: 3px;
    }
  }

  // 列表
  .businesslist {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;



    .businesslist_box {
      cursor: pointer;
      width: 16%;
      height: 400px;
      float: left;
      margin-top: 20px;
      position: relative;
      transition: all 0.5s linear 0.5s;

      opacity: 0.9;

      .cententone_big {
        // position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        transition: all 0.3s ease;
        justify-content: center;
      }

      .cententone_title {
        z-index: 9999;
        position: absolute;
        top: 0;
        width: 80px;
        margin-top: 40px;
        margin-left: 10px;
        margin-right: auto;
        height: 100px;
        font-size: 28px;
        text-align: left;
        color: white;
        letter-spacing: 5px;
        float: left;
      }

      .cententone_two {
        z-index: 9999;
        position: absolute;
        top: 0;
        width: 200px;
        margin-top: 80px;
        margin-left: 100px;
        height: 100px;
        font-size: 28px;
        text-align: left;
        color: white;
        letter-spacing: 5px;
        float: left;
      }

      .cententone_title_p {
        font-size: 18px;
        font-weight: 550;
        color: white;
        margin-left: 70px;
        margin-top: 41px;
      }

      .cententone_title_eg {
        z-index: 9999;
        position: absolute;
        top: 0;
        width: 130px;
        margin-top: 140px;
        margin-left: auto;
        margin-right: auto;
        height: 100px;
        font-size: 28px;
        text-align: center;
        color: white;

        span {
          font-size: 18px;
          margin-top: -45px;
          margin-right: -5px;
          float: right;
          font-weight: 550;
          display: inline-block;
        }

        p:nth-child(1) {
          z-index: 9999;
          font-size: 35px;
          font-weight: 550;
          margin-top: 5px;
          font-family: 'Sofia Sans';

        }

        .exh {
          z-index: 9999;
          font-size: 20px;
          font-weight: 550;
          margin-top: 5px;
          text-align: center;
          font-family: 'Sofia Sans2';

        }
      }

      a {
        z-index: 9999;
        position: absolute;
        bottom: 30px;
        width: 100%;
        height: 30px;
        font-size: 15px;
        letter-spacing: 2px;
        text-align: center;
        text-decoration: none;
        color: white;
        line-height: 30px;
        display: inline-block;


      }

      .jiantou {
        z-index: 9999;
        position: absolute;
        bottom: 30px;
        width: 40px;
        height: 30px;
        right: 40px;
      }
    }

    .businesslist_box:nth-child(1) {
      background: url('../imager/list/1.jpg');
      background-size: 100% 100%;

      // background-size: 100% 100%;
      // transition: all 0.5s linear 0.5s;
      // opacity: 0.9;
    }

    .businesslist_box:nth-child(2) {
      background: url('../imager/list/2.jpg');
      background-size: 100% 100%;

      // background-size: 100% 100%;
      // transition: all 0.5s linear 0.5s;
      // opacity: 0.9;

    }

    .businesslist_box:nth-child(3) {
      background: url('../imager/list/3.jpg');
      background-size: 100% 100%;
      // transition: all 0.5s linear 0.5s;
      // opacity: 0.9;
    }

    .businesslist_box:nth-child(4) {
      background: url('../imager/list/4.jpg');
      background-size: 100% 100%;
      // transition: all 0.5s linear 0.5s;
      // opacity: 0.9;
    }

    .businesslist_box:nth-child(5) {
      background: url('../imager/list/5.jpg');
      background-size: 100% 100%;
      // transition: all 0.5s linear 0.5s;
      // opacity: 0.9;
    }

    .businesslist_box:nth-child(6) {
      background: url('../imager/list/6.jpg');
      background-size: 100% 100%;
      // transition: all 0.5s linear 0.5s;
      // opacity: 0.9;
    }

    .businesslist_box:nth-child(1):hover {
      background: url('../imager/list/ac1.jpg');
      background-size: 100% 100%;
      transition: all 1s;

    }

    .businesslist_box:nth-child(2):hover {
      background: url('../imager/list/ac2.jpg');
      background-size: 100% 100%;
      transition: all 1s;

    }

    .businesslist_box:nth-child(3):hover {
      background: url('../imager/list/ac3.jpg');
      background-size: 100% 100%;
      transition: all 1s;

    }

    .businesslist_box:nth-child(4):hover {
      background: url('../imager/list/ac4.jpg');
      background-size: 100% 100%;
      transition: all 1s;

    }

    .businesslist_box:nth-child(5):hover {
      background: url('../imager/list/ac5.jpg');
      background-size: 100% 100%;
      transition: all 1s;

    }

    .businesslist_box:nth-child(6):hover {
      background: url('../imager/list/ac6.jpg');
      background-size: 100% 100%;
      transition: all 1s;

    }

    .businesslist_box:nth-child(1) {
      margin-left: 0;
    }
  }
}


// 轮播
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}


// 经典案例
.jdanlibox {
  width: 1380px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .title {
    position: absolute;
    bottom: 0;
    right: 30px;
    bottom: 30px;
    color: white;
    font-family: 'JT';
    font-size: 12px;
    pointer-events: none;

    P {
      margin-top: 5px;
    }
  }

  .bannerPone_ZZ {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    z-index: 9;
    display: none;

    .bannerPone_ZZleft {
      width: 50%;
      height: 100%;
      background-image: url('../imager/list/redzhezhao.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      float: left;
      display: flex;
      align-items: center;

      img:nth-child(1) {
        width: 450px;
        height: 120px;
        margin-left: 50px;
      }

      img:nth-child(2) {
        width: 40px;
        height: 40px;
        float: right;
        margin-left: 80px;
        margin-top: 20px;
      }
    }

    .bannerPone_ZZright {
      width: 50%;
      height: 100%;
      background-image: url('../imager/list/huizhezhao.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      // float: left;
      margin-left: 50%;
      display: none;

      P {
        width: 100%;
        height: 20px;
        display: inline-block;
        color: white;
        margin-left: 80px;
      }

      P:nth-child(1) {
        margin-top: 30px;
        letter-spacing: 2px;
        font-family: 'JT';

      }
    }

    .bannerPone_ZZrightactive {
      display: block;

    }
  }

  .Maskactive {
    display: block;
  }
}
</style>