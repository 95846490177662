import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/about.vue')
    }
  },
  {
    path: '/aboutdetails',
    name: 'aboutdetails',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/aboutdetails.vue')
    }
  },
  {
    path: '/news',
    name: 'news',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/news.vue')
    }
  },
  {
    path: '/newsdetails',
    name: 'newsdetails',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/newsdetails.vue')
    }
  },
  // business matrix 业务矩阵
  {
    path: '/businessMatrix',
    name: 'businessMatrix',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/businessMatrix.vue')
    }
  },
  //经典案例
  {
    path: '/cases',
    name: 'cases',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/cases.vue')
    }
  },
  // 详情页
  {
    path: '/casedetails',
    name: 'casedetails',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/casedetails.vue')
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
