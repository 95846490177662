<template>
  <div>
    <div class=index_top>
      <div class="centent">
        <div class="logo">
          <img src="../imager/logo.png" alt="">
        </div>
        <!-- 下拉 -->
        <div class="index_top_seclect" @mouseleave="handleMouseLeave()">
          <div v-for="(item, index) in topdata" :key="item.name"  @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)" class="cententbox">
            <p @click="btn(index, item.url)" 
              :class="topactove == index ? 'pactive' : ''"> {{ item.name }}
            </p>
          </div>
        </div>
        <!-- 搜索 -->
        <div class="index_top_seache">
        </div>
        <!-- 中英文切换 -->
        <!-- 搜索 -->
        <div class="index_top_inlantab">
          <span>中&nbsp;</span>
          <span style="color:white;">|</span>
          <span>&nbsp;EN</span>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div :class="mask"   @mouseenter="handleMouseEnter(1)" @mouseleave="handleMouseLeave()" @animationend="animationEnd(3)"> 
      <div class="maskbg"> </div>
      <div class="aboutme">
        <div class="centen_box">
          <h1 class="title">关于我们</h1>
          <div class="left_about">
            <h1>企业介绍</h1>
            <h1>企业资质</h1>
            <h1>企业咨询</h1>
            <!-- 介绍 -->
            <div class="introduce">
              <p><a href="">概括</a></p>
              <p><a href="">文化</a></p>
              <p><a href="">环境</a></p>

            </div>
            <!-- 资质 -->
            <div class="qualifications">
              <p><a href="">1</a></p>
              <p><a href="">2</a></p>
              <p><a href="">3</a></p>
              <p><a href="">4</a></p>
              <p><a href="">5</a></p>
              <p><a href="">6</a></p>

            </div> <!-- 咨询 -->
            <div class="seek ">
              <p><a href="">1</a></p>
              <p><a href="">2</a></p>

            </div>
          </div>
          <div class="right_about">
            <h1>企业文化</h1>
            <h1>在研课题</h1>
            <h1>加入我们</h1>
            <!-- 介绍 -->
            <div class="introduce">
              <p><a href="">概括</a></p>
              <p><a href="">文化</a></p>
              <p><a href="">环境</a></p>

            </div>
            <!-- 资质 -->
            <div class="qualifications">
              <p><a href="">1</a></p>
              <p><a href="">2</a></p>
              <p><a href="">3</a></p>
              <p><a href="">4</a></p>

            </div> <!-- 咨询 -->
            <div class="seek ">
              <p><a href="">1</a></p>
              <p><a href="">2</a></p>

            </div>
          </div>
        </div>

      </div>
    </div>











  </div>
</template>

<script>
export default {
  name: 'PcTop',
  props: {
    active: Number
  },
  data() {
    return {
      // 头部active
      topactove: 0,
      // lang
      topdata: [
        {
          name: '主页',
          url: "/"
        },
        {
          name: '关于我们',
          url: "/about"

        },
        {
          name: '新闻中心',
          url: "/news"
        }, {
          name: '业务矩阵',
          url: "/businessMatrix"
        }, {
          name: '经典案例',
          url: "/cases"
        }
      ],
      mask: "mask",
      isOk:false
    };
  },

  mounted() {
    this.topactove = this.active
  },

  methods: {
    handleMouseEnter(index) {
      // this.topactove = index;
      if (index == 1) {
        this.mask = 'mask maskactive  animate__animated  animate__fadeInDown '

      }

    },
    handleMouseLeave(index) {
      if(this.isOk){
        this.mask = 'mask maskactive animate__animated  animate__fadeOutUp   animate__faster '
      }

    },
 
    // 改变top
    btn(e, url) {
      this.topactove = e;
      //   console.log(e)
      this.$router.push(`${url}`, () => { })
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0
    },
    animationEnd(e){
      this.isOk=true;
          // this.topactove = this.active;

    }
  },
};
</script>

<style lang="scss" scoped>
// 头部
.index_top {
  width: 100%;
  height: 100px;
  background-color: #b7001e;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999999;

  .centent {
    width: 1380px;
    height: 100%;
    display: flex;
    align-items: center;

    .logo {
      margin-top: 5px;
      cursor: pointer;

      img {
        width: 80px;
      }
    }

    .index_top_seclect {
      margin-left: 100px;
      width: 600px;
      height: 60%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: white;
      font-family: "微软雅黑";
      font-weight: 500;
      letter-spacing: 3px;
      // font-size: 18px;
      margin-top: 30px;

      .cententbox:nth-child(2){
        height: 135px;
        p{
        margin-top: 50px;
      }
      }
      .cententbox {
        cursor: pointer;
        text-align: center;
        float: left;
        align-items: center;
        height: 35px;
        width: 120px;
       margin-left: 10px;
        p {
          width: 100%;
          // height: 30px;
          height: 35px;
        }
    
      }

      .pactive {
        width: 100%;
        // height: 30px;
        height: 35px;
        font-size: 17px;
        // font-size: 18px;
        font-weight: 550;
        // border-bottom: 2.5px solid white;
      }
    }

    // 搜索
    .index_top_seache {
      margin-left: 100px;
      width: 300px;
      height: 100%;
      // background: seagreen;
    }

    // 语言切换
    .index_top_inlantab {
      cursor: pointer;
      margin-left: 100px;
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      margin-top: 20px;

    }
  }
}

.mask {
  cursor: pointer;
  height: calc(100vh - 100px);
  position: absolute;
  margin-left: 0px;
  width: 100%;
  top: 100px;
  z-index: 99999;
  pointer-events: all;
  display: none;

  .maskbg {
    background: #322e29;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7 !important;

  }

}

.maskactive {
  display: block;
}

//关于我们
.aboutme {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 80px;
  width: 100%;
  height: 600px;

  .centen_box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;

    .left_about {
      h1 {
        font-size: 23px;
        color: white;
        float: left;
        margin-top: 5px;
        margin-left: 100px;
        font-family: 'JT';
        letter-spacing: 3px;
      }

      .introduce {
        width: 100px;
        height: 100px;
        margin-left: 50px;
        float: left;
        margin-top: -145px;

        p {
          margin-top: 5px;
          width: 100%;
          height: 30px;
          display: inline-block;

          a {
            text-decoration: none;
            color: #999999;
            font-family: 'JT';
            font-size: 18px;

          }
        }
      }

      .qualifications {
        width: 100px;
        height: 100px;
        margin-left: 50px;
        float: left;
        margin-top: -15px;

        p {
          margin-top: 5px;
          width: 100%;
          height: 30px;
          display: inline-block;

          a {
            text-decoration: none;
            color: #999999;
            font-family: 'JT';
            font-size: 18px;

          }
        }
      }

      .seek {
        width: 100px;
        height: 100px;
        margin-left: -100px;
        float: left;
        margin-top: 249px;

        p {
          margin-top: 5px;
          width: 100%;
          height: 30px;
          display: inline-block;

          a {
            text-decoration: none;
            color: #999999;
            font-family: 'JT';
            font-size: 18px;

          }
        }
      }

      h1:nth-child(2) {
        clear: both;
        float: left;
        font-size: 23px;
        color: white;
        margin-top: 90px;
        margin-left: 223px;
      }

      h1:nth-child(3) {
        clear: both;
        float: left;
        font-size: 23px;
        color: white;
        margin-top: 250px;
        margin-left: 223px;
      }
    }
    .right_about {
      float: left;
      h1 {
        font-size: 23px;
        color: white;
        float: left;
        margin-top: -145px;
        margin-left: 224px;
        font-family: 'JT';
        letter-spacing: 3px;
      }

      .introduce {
        width: 100px;
        height: 100px;
        margin-left: 50px;
        float: left;
        margin-top: -145px;

        p {
          margin-top: 5px;
          width: 100%;
          height: 30px;
          display: inline-block;

          a {
            text-decoration: none;
            color: #999999;
            font-family: 'JT';
            font-size: 18px;

          }
        }
      }

      .qualifications {
        width: 100px;
        height: 100px;
        margin-left: 50px;
        float: left;
        margin-top: -15px;

        p {
          margin-top: 5px;
          
          width: 100%;
          height: 30px;
          display: inline-block;

          a {
            text-decoration: none;
            color: #999999;
            font-family: 'JT';
            font-size: 18px;

          }
        }
      }

      .seek {
        width: 100px;
        height: 100px;
        margin-left: -100px;
        float: left;
        margin-top: 249px;

        p {
          margin-top: 5px;
          width: 100%;
          height: 30px;
          display: inline-block;

          a {
            text-decoration: none;
            color: #999999;
            font-family: 'JT';
            font-size: 18px;

          }
        }
      }

      h1:nth-child(2) {
        clear: both;
        float: left;
        font-size: 23px;
        color: white;
        margin-top: -27px;
        margin-left: 223px;
      }

      h1:nth-child(3) {
        clear: both;
        float: left;
        font-size: 23px;
        color: white;
        margin-top: 250px;
        margin-left: 223px;
      }
    }
    .title {
      letter-spacing: 3px;
      color: white;
      font-size: 28px;
      float: left;
    }
  }


}

// animate__slideInDown
</style> 